
import { mapMutations } from "vuex";

export default {
	methods: {
		...mapMutations({
			setModalMenu: 'modal/modal-menu/setModalMenu',
			setModalMarks: 'modal/modal-marks/setModalMarks',
			setModalSearch: 'modal/modal-search/setModalSearch',
		}),
		closeModals() {
			this.setModalMenu(false)
			this.setModalMarks(false)
			this.setModalSearch(false)
		}
	},
	computed: {
		nav() {
			return [
				// {
				// 	title: 'НОВЫЕ АВТО',
				// 	link: '/new'
				// },
				{
					title: 'АВТО С ПРОБЕГОМ',
					link: '/cars'
				},
				{
					title: 'АВТОКРЕДИТ',
					link: '/credit'
				},
				{
					title: 'TRADE-IN',
					link: '/trade-in'
				},
				{
					title: 'ВЫКУП',
					link: '/buyout'
				},
				{
					title: 'РАССРОЧКА',
					link: '/installment'
				},

				{
					title: 'КОНТАКТЫ',
					link: '/contacts'
				},
				
			]
		},

		route(){
			return this.$route.path
		}
	},
	
}
